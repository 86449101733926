import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

const MenuStyles = styled.nav`
  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    transition: var(--transition-time);
  }

  a:focus,
  a:hover {
    text-decoration: underline;
  }
`
const Menu = () => (
  <StaticQuery
    query={graphql`
      {
        allTaxonomyTermMicrosites {
          edges {
            node {
              name
              drupal_internal__tid
              field_ms_menu_items {
                title
                uri
              }
            }
          }
        }
      }
    `}
    render={data => (
      <MenuStyles>
        {data.allTaxonomyTermMicrosites.edges
          .filter(
            thisMicrositesContent =>
              parseInt(thisMicrositesContent.node.drupal_internal__tid) ===
              parseInt(`${process.env.MICROSITE_TAXONOMY_TERM_ID}`)
          )
          .map(thisMicrosite => (
            <>
              {thisMicrosite?.node?.field_ms_menu_items && (
                <ul className="menu">
                  {thisMicrosite.node.field_ms_menu_items.map(menuItem => (
                    <li key={menuItem.uri}>
                      <Link to={menuItem.uri.replace('internal:', '')}>
                        {menuItem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ))}
      </MenuStyles>
    )}
  />
)

export default Menu
