import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'
import GlobalStyles from '../styles/global-styles'
import Header from './header'
import Footer from './footer'

const PageContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  > footer {
    margin-top: auto;
  }
  .breadcrumb__list {
    display: flex;
    list-style-type: none;
    padding: 0;
    .breadcrumb__separator {
      color: var(--color-brand);
      margin: 0 0.5rem;
    }
  }
`
const LayoutGlobalStyle = createGlobalStyle`
  :root {
    --header-bg-color: ${props => props.headerBgColor};
    --header-text-color: ${props => props.headerTextColor};
    --footer-bg-color: ${props => props.footerBgColor};
    --footer-text-color: ${props => props.footerTextColor};
    --color-brand: ${props => props.colorBrand};
    --font-heading: ${props => props.headingFont};
    --font-body: ${props => props.bodyFont};
  }
`
export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    {
      allTaxonomyTermMicrosites {
        edges {
          node {
            drupal_internal__tid
            field_ms_brand_color
            field_ms_header_background_color
            field_ms_footer_background_color
            field_ms_footer_text_color
            field_ms_header_text_color
            field_ms_header_text_color
            field_ms_heading_font
            field_ms_body_font
          }
        }
      }
    }
  `)

  const micrositesThemeSettings = data.allTaxonomyTermMicrosites.edges.filter(
    thisMicrositesThemeSettings =>
      parseInt(thisMicrositesThemeSettings.node.drupal_internal__tid) ===
      parseInt(`${process.env.MICROSITE_TAXONOMY_TERM_ID}`)
  )

  const headingFont = micrositesThemeSettings[0]?.node?.field_ms_heading_font
    ? micrositesThemeSettings[0].node.field_ms_heading_font
    : 'Roboto'

  const bodyFont = micrositesThemeSettings[0]?.node?.field_ms_body_font
    ? micrositesThemeSettings[0].node.field_ms_body_font
    : 'Roboto'
  const headingFontToUse = headingFont.replace(/\s+/g, '+')
  const bodyFontToUse = bodyFont.replace(/\s+/g, '+')

  return (
    <>
      <PageContainerStyles>
        <GlobalStyles />
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link
            href={`https://fonts.googleapis.com/css2?family=${headingFontToUse}:wght@400;700&display=swap`}
            rel="stylesheet"
          />
          <link
            href={`https://fonts.googleapis.com/css2?family=${bodyFontToUse}:wght@400;700&display=swap`}
            rel="stylesheet"
          />
        </Helmet>
        <LayoutGlobalStyle
          colorBrand={
            micrositesThemeSettings[0]?.node?.field_ms_brand_color
              ? micrositesThemeSettings[0].node.field_ms_brand_color
              : '#e44c65'
          }
          headerBgColor={
            micrositesThemeSettings[0]?.node?.field_ms_header_background_color
              ? micrositesThemeSettings[0].node.field_ms_header_background_color
              : '#eaeaea'
          }
          headerTextColor={
            micrositesThemeSettings[0]?.node?.field_ms_header_text_color
              ? micrositesThemeSettings[0].node.field_ms_header_text_color
              : '#333'
          }
          footerBgColor={
            micrositesThemeSettings[0]?.node?.field_ms_footer_background_color
              ? micrositesThemeSettings[0].node.field_ms_footer_background_color
              : '#eaeaea'
          }
          footerTextColor={
            micrositesThemeSettings[0]?.node?.field_ms_footer_text_color
              ? micrositesThemeSettings[0].node.field_ms_footer_text_color
              : '#333'
          }
          headingFont={headingFont}
          bodyFont={bodyFont}
        />
        <Header />
        <main>
          <div>{children}</div>
        </main>
        <Footer />
      </PageContainerStyles>
    </>
  )
}
