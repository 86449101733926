// Please us this file sparingly. It's only for global elements that we want
// to apply to every page.
//
// If you are styling a custom component, do so in that component's file.

import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  
  /* Element Styling */
  :root {
    --width-medium: 40rem;
    --header-height: 4rem;
    --transition-time: .3s;
  }

  body {
    margin: 0;
    line-height: 1.5;
    font-family: var(--font-body), sans-serif;
    font-weight: 400;
  }

  main {
    padding-top: calc(var(--header-height) + 4rem);
    padding-bottom: 4rem;
  } 


  main a {
    color: var(--color-brand);
    text-decoration: underline
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-weight: 700;
    color: var(--color-brand);
    font-family: var(--font-heading), sans-serif;
  }

  h1 {
    margin-top: 3rem;
    margin-bottom: 4rem;
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }
  
  p {
    margin-top: 0;
  }

  a {
    text-decoration: none;
    transition: var(--transition-time);
    &:focus,
    &:hover {
      color: var(--color-brand);
      text-decoration: none;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 3px solid var(--color-brand);
  }
  
  /* Generic Classes Styling */
  .layout-contained {
    margin: 0 auto;
    max-width: calc(100% - 4em);
    width: 70rem;
  }
  .layout-contained--medium {
    max-width: var(--width-medium);
  }
  .layout-contained__article-width {
    max-width: var(--width-medium);
  }

  .body-content > * {
    + h2, + h3, + h4, + h5 {
      margin-top: 4rem;
    }
  }
`
export default GlobalStyles
