import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { TwitterIcon, FacebookIcon, LinkedInIcon, InstagramIcon, YoutubeIcon } from './icons'

const SocialShareStyles = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
  padding: 1rem;
  text-align: center;

  li {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  li::marker {
    color: transparent;
  }
  svg {
    width: 1.5rem;
    height: auto;
    transition: var(--transition-time);
    path {
      fill: var(--footer-text-color);
    }
  }
  a:focus svg,
  a:hover svg {
    transform: translateY(-2px);
  }
`

export default function SocialLinks() {
  const data = useStaticQuery(graphql`
    {
      allTaxonomyTermMicrosites {
        edges {
          node {
            field_ms_facebook
            field_ms_linkedin
            field_ms_twitter
            field_ms_instagram
            field_ms_youtube
            drupal_internal__tid
          }
        }
      }
    }
  `)

  const micrositesThemeSettings = data.allTaxonomyTermMicrosites.edges.filter(
    thisMicrositesThemeSettings =>
      parseInt(thisMicrositesThemeSettings.node.drupal_internal__tid) ===
      parseInt(`${process.env.MICROSITE_TAXONOMY_TERM_ID}`)
  )

  const hasSocialLinks = micrositesThemeSettings[0].node.field_ms_facebook || micrositesThemeSettings[0].node.field_ms_twitter || micrositesThemeSettings[0].node.field_ms_linked || micrositesThemeSettings[0].node.field_ms_instagram || micrositesThemeSettings[0].node.field_ms_youtube

  return (
    hasSocialLinks ? (
    <>
      <div className="layout-contained padding-horizontal">
        <SocialShareStyles>
        {micrositesThemeSettings[0]?.node.field_ms_facebook ? (
          <li>
            <a href={micrositesThemeSettings[0].node.field_ms_facebook }>
              <FacebookIcon />
            </a>
          </li>
        ) : (
          ''
        )}
        {micrositesThemeSettings[0]?.node.field_ms_twitter ? (
          <li>
            <a href={micrositesThemeSettings[0].node.field_ms_twitter}>
              <TwitterIcon />
            </a>
          </li>
        ) : (
          ''
        )}
        {micrositesThemeSettings[0]?.node.field_ms_linkedin ? (
          <li>
            <a href={micrositesThemeSettings[0].node.field_ms_linkedin}>
              <LinkedInIcon />
            </a>
          </li>
        ) : (
          ''
        )}
        {micrositesThemeSettings[0]?.node.field_ms_instagram ? (
          <li>
            <a href={micrositesThemeSettings[0].node.field_ms_instagram}>
              <InstagramIcon />
            </a>
          </li>
        ) : (
          ''
        )}
        {micrositesThemeSettings[0]?.node.field_ms_youtube ? (
          <li>
            <a href={micrositesThemeSettings[0].node.field_ms_youtube}>
              <YoutubeIcon />
            </a>
          </li>
        ) : (
          ''
        )}
        </SocialShareStyles>
      </div>
    </>
  ) : (
    ''
    )
  )
}
