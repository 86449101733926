import React from 'react'
import styled from 'styled-components'
import Menu from '../menu'
import SocialLinks from '../SocialLinks'

const FooterStyles = styled.footer`
  padding: 1rem 0;
  color: var(--footer-text-color);
  background-color: var(--footer-bg-color);
  a {
    color: var(--footer-text-color);
  }

  a:focus,
  a:hover {
    color: var(--footer-text-color);
  }

  p {
    margin-bottom: 0;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .menu li {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    :after {
      content: "|";
      margin-left: 1rem;
    }
    :last-child {
      :after {
        content: "";
      }
    }
  }
`
export default function Footer() {
  return (
    <>
      <FooterStyles>
        <div className="layout-contained padding-horizontal">
          <Menu menuLayout="horizontal" />
          <SocialLinks />
        </div>
      </FooterStyles>
    </>
  )
}
