import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import Menu from '../menu'

const HeaderStyles = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  height: var(--header-height);
  z-index: 100;
  width: 100%;
  padding: 2rem 0;
  color: var(--header-text-color);
  background-color: var(--header-bg-color);

  a {
    color: var(--header-text-color);
    &:hover,
    &:focus {
      color: var(--header-text-color);
    }
  }

  .logo-container img {
    max-height: var(--header-height);
    width: auto;
  }

  p {
    margin-bottom: 0;
  }

  .header__menu-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    border: 0;
    background: transparent;
    border-radius: 0;
    height: 70px;
    width: 35px;
    cursor: pointer;
    margin-left: 25px;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .icon-bar {
    display: block;
    width: 100%;
    height: 3px;
    background: var(--header-text-color);
    transition: var(--transition-time);
  }
  .icon-bar + .icon-bar {
    margin-top: 5px;
  }

  .header__nav-content {
    padding: 1.5rem;
    width: 90%;
    max-width: 232px;
    position: absolute;
    top: calc(var(--header-height) + 2rem);
    left: 0;
    height: calc(100vh - 70px);
    background: var(--header-bg-color);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateX(-100%);
    transition: var(--transition-time);
    will-change: transform;
  }

  .header__nav-content--active {
    transform: translateX(0);
  }

  .header__nav-content ul li + li {
    border-top: 1px solid var(--header-text-color);
  }

  .header__nav-content li a {
    padding: 1rem 0;
    display: block;
    text-transform: uppercase;
  }
`

const HeaderInnerStyles = styled.div`
  display: flex;
  justify-content: space-between;
`
export default function Header() {
  const [menuActive, setMenuActive] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        {
          allTaxonomyTermMicrosites {
            edges {
              node {
                name
                drupal_internal__tid
                field_ms_logo {
                  alt
                }
                relationships {
                  field_ms_logo {
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(width: 200, placeholder: NONE)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <HeaderStyles className="header">
          <div className="layout-contained padding-horizontal">
            <HeaderInnerStyles>
              <div className="logo-container">
                <Link to="/" className="logo-link">
                  {data.allTaxonomyTermMicrosites.edges
                    .filter(
                      thisMicrositesContent =>
                        parseInt(
                          thisMicrositesContent.node.drupal_internal__tid
                        ) ===
                        parseInt(`${process.env.MICROSITE_TAXONOMY_TERM_ID}`)
                    )
                    .map(thisMicrosite => (
                      <>
                        {thisMicrosite?.node?.relationships?.field_ms_logo ? (
                          <GatsbyImage
                            image={
                              thisMicrosite.node.relationships.field_ms_logo
                                .localFile.childImageSharp.gatsbyImageData
                            }
                            alt={
                              thisMicrosite.node.field_ms_logo.alt
                                ? thisMicrosite.node.field_ms_logo.alt
                                : thisMicrosite.node.name
                            }
                          />
                        ) : (
                          <div>{thisMicrosite.node.name}</div>
                        )}
                      </>
                    ))}
                </Link>
              </div>
              <div id="header__nav-container" className="header__nav-container">
                <div className="header__content-bg" />

                <button
                  type="button"
                  className="header__menu-button"
                  aria-controls="header__nav-content"
                  aria-expanded={`${!!menuActive}`}
                  onClick={() => {
                    setMenuActive(!menuActive)
                  }}
                >
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
              </div>
            </HeaderInnerStyles>
          </div>
          <div
            id="header__nav-content"
            className={`${
              menuActive
                ? 'header__nav-content header__nav-content--active'
                : 'header__nav-content'
            }`}
          >
            <Menu />
          </div>
        </HeaderStyles>
      )}
    />
  )
}
